<template>
  <v-select
    autocomplete
    label="Sincronizações"
    placeholder="Selecione..."
    :rules="[() => !!id || 'Campo obrigatório']"
    v-model="id"
    :items="tipoSincronizacao"
    item-value="Id"
    @input="setSelected"
    item-text="Nome"
    required
  ></v-select>
</template>
<script>
import { serverBus } from "../../main"
export default {
  data: () => ({
    tipoSincronizacao: [],
    id: ""
  }),
  created() {
    var tipo1 = new Object()
    tipo1.Id = 1
    tipo1.Nome = "Todas"
    var tipo2 = new Object()
    tipo2.Id = 2
    tipo2.Nome = "Vendedor"
    var tipo3 = new Object()
    tipo3.Id = 3
    tipo3.Nome = "Nota saída"
    var tipo4 = new Object()
    tipo4.Id = 4
    tipo4.Nome = "Nota retorno"
    var tipo5 = new Object()
    tipo5.Id = 5
    tipo5.Nome = "Pagamento"
    
    this.tipoSincronizacao.push(tipo1)
    this.tipoSincronizacao.push(tipo2)
    this.tipoSincronizacao.push(tipo3)
    this.tipoSincronizacao.push(tipo4)
    this.tipoSincronizacao.push(tipo5)
  },
  methods: {
    setSelected(value) {
      serverBus.$emit("tipoSincronizacaoSelected", value)
    }
  }
}
</script>
