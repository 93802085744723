
<template>
  <div id="pageSacola">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <v-card>
            <v-toolbar flat dense cad>
              <v-toolbar-title class="subheading">Consulta de sincronizações</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="">
              <sincronizacao-table></sincronizacao-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import SincronizacaoTable from "@/components/sincronizacao/tableSincronizacao"
export default {
  components: {
    SincronizacaoTable
  }
}
</script>
