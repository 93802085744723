<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <select-funcionario v-if="!view" class="mr-2"></select-funcionario>
          <select-tipo-sincronizacao></select-tipo-sincronizacao>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-menu
            class="pr-2"
            ref="dtSaida"
            lazy
            :close-on-content-click="false"
            v-model="dtNtSaida"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataInicio"
          >
            <v-text-field
              slot="activator"
              label="Data início"
              v-model="dataInicio"
              :rules="[() => !!dataInicio || 'Campo obrigatório']"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataInicio" no-title scrollable locale="pt-br">
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dtNtSaida = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dtSaida.save(dataInicio)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            class="pr-2"
            ref="dtReturn"
            lazy
            :close-on-content-click="false"
            v-model="dtPrevReturn"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataFim"
          >
            <v-text-field
              slot="activator"
              label="Data final"
              v-model="dataFim"
              append-icon="event"
              :rules="[() => !!dataFim || 'Campo obrigatório']"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataFim" no-title scrollable :min="dataInicio" locale="pt-br">
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dtPrevReturn = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dtReturn.save(dataFim)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn @click="pesquisar" outline color="primary">Pesquisar</v-btn>
        </div>
        <div>
          <progress-circular></progress-circular>
        </div>
        <v-snackbar :color="'error'" v-model="snackbar" :top="true">
          {{ text }}
          <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
      </v-flex>
    </v-layout>
    <div>
      <v-toolbar card color="white">
        <v-text-field
          flat
          solo
          prepend-icon="search"
          placeholder="Filtrar"
          v-model="search"
          hide-details
          class="hidden-sm-and-down"
        ></v-text-field>
        <v-btn icon >
          <v-icon>search</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="sincronizacoes"
        :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
        class="elevation-1"
      >
        <template v-slot:items="props">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.promotor }}</td>
          <td>{{ props.item.dataString }}</td>
          <td>{{ props.item.tipoComandoString }}</td>
          <td>{{ props.item.tipoEntidadeString }}</td>
          <td>{{ props.item.situacaoSincronizacaoString }}</td>
          <td>
            <v-btn @click="visualizar(props.item.id)" depressed icon dark color="primary" small>
              <v-icon small>remove_red_eye</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </div>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import ServicoSincronizacao from "../../servicos/servicoSincronizacao"
import SelectFuncionario from "../funcionario/selectFuncionario.vue"
import SelectTipoSincronizacao from "./selectTipoSincronizacao.vue"
import ProgressCircular from "../progressCircular/progressCircular"
const servicoSincronizacao = new ServicoSincronizacao()
export default {
  components: {
    SelectFuncionario,
    SelectTipoSincronizacao,
    ProgressCircular
  },
  data() {
    return {
      search: "",
      idFuncionario: null,
      tipoSincronizacao: null,
      sincronizacoes: [],
      sincSemfiltro: [],
      dtNtSaida: false,
      dataInicio: null,
      dtPrevReturn: false,
      dataFim: null,
      text: "",
      headers: [
        { text: "Código", align: "center", value: "id", sortable: false },
        { text: "Promotor", align: "center", value: "promotor", sortable: false },
        { text: "Data inclusão", value: "dataString", sortable: false },
        { text: "Tipo de comando", value: "tipoComandoString", sortable: false },
        { text: "Tipo de entidade", value: "tipoEntidadeString", sortable: false },
        { text: "Situação", value: "situacaoSincronizacaoString", sortable: false },
        { text: "", value: "action", align: "right", sortable: false }
      ]
    }
  },
  created() {
    serverBus.$on("funcionarioSelected", value => {
      this.idFuncionario = value
    })
    serverBus.$on("tipoSincronizacaoSelected", value => {
      this.tipoSincronizacao = value
    })
  },
  methods: {
    validarData(data) {
      var dataComBarra = data.split("/")
      if (dataComBarra.length > 1) {
        return new Date(dataComBarra[2], dataComBarra[1], dataComBarra[0])
      } else {
        return new Date(data)
      }
    },
    visualizar(id) {
      this.$router.push({ name: "view-sincronizacao", params: { id: id } })
    },
    pesquisar() {
      this.abrirProgressCircular()
      if (this.$refs.form.validate()) {
        servicoSincronizacao
          .getSincronizacoesComFiltro(this.idFuncionario, this.tipoSincronizacao, this.dataInicio, this.dataFim)
          .then(
            res => {
              if (res.status === 200 && res.data.length === undefined) {
                this.fecharProgressCircular()
                this.text = res.data[0].title + " - " + res.data[0].detail
              } else {
                this.fecharProgressCircular()
                this.sincronizacoes = res.data
              }
            },
            err => {
              this.fecharProgressCircular()
              // eslint-disable-next-line
              console.log(err)
            }
          )
      }
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    }
  }
}
</script>
